* {
    scrollbar-width: thin;
    scrollbar-color: var(--chakra-colors-gray-400);
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--chakra-colors-gray-400);
  border-radius: 8px;
  border: none;
}